import { base64DecToArr, base64EncArr } from './base64'

export const ApiCallStatus = {
  UNCALLED: 0,
  CALLING: 1,
  SUCCESS: 2,
  ERROR: 3,
}
export class ApiCall {
  constructor(n) {
    this.name = n
    this.statusName = n + 'Status'
    this.requireAuth = false
    this.isStream = false
    this.cache = false
    return this
  }
  stream() {
    this.isStream = true
    return this
  }
  unary() {
    this.isStream = false
    return this
  }
  withRequest(f) {
    this.reqBuilder = f
    return this
  }
  withServiceCall(f) {
    this.serviceCall = f
    return this
  }
  onSuccess(f) {
    this.resProcesser = f
    return this
  }
  onUpdate(f) {
    this.updateProcesser = f
    return this
  }
  onError(f) {
    this.errProcesser = f
    return this
  }
  authRequired() {
    this.requireAuth = true
    return this
  }
  authOptional() {
    this.requireAuth = false
    return this
  }
  withTimeout(timeoutInSeconds) {
    this.timeoutInSeconds = timeoutInSeconds
    return this
  }
  cached({ cacheName, cachedMessage, keyFn, ttlInSeconds }) {
    this.cache = true
    this.cacheName = cacheName
    this.cacheTtlInSeconds = ttlInSeconds
    this.cachedMessage = cachedMessage
    this.keyFn = keyFn
    return this
  }
  invalidatingCache({ cacheName, keyFn }) {
    this.invalidateCache = true
    this.invalidateCacheName = cacheName
    this.invalidateKeyFn = keyFn
    return this
  }
  protobufToString(msg) {
    return base64EncArr(msg.serializeBinary())
  }
  stringToProtobuf(protobuf, data) {
    return protobuf.deserializeBinary(base64DecToArr(data))
  }
  build() {
    let apiCall = this
    return async function (ctx, data) {
      var tokenCall = apiCall.requireAuth
        ? 'user/optionalUserToken'
        : 'user/ensureUserToken'

      return ctx.dispatch(tokenCall, null, { root: true }).then((ut) => {
        if (apiCall.requireAuth && !ut)
          throw 'Auth required for ' + apiCall.name
        var req = apiCall.reqBuilder(data, ut, ctx)
        if (req === null) return null
        console.log('Calling', apiCall.name, req.toObject())
        var cacheKey = null
        if (apiCall.cache) {
          cacheKey = apiCall.cacheName + ':' + apiCall.keyFn(data, ctx)
          console.log('Checking cache with key: ', cacheKey)
          var cachedResponse = localStorage.getItem(cacheKey)
          if (cachedResponse) {
            cachedResponse = JSON.parse(cachedResponse)
            console.log('Found cached object', cachedResponse)
            if (
              cachedResponse.savedAt &&
              cachedResponse.savedAt >=
                Date.now() - apiCall.cacheTtlInSeconds * 1000
            ) {
              var res = apiCall.stringToProtobuf(
                apiCall.cachedMessage,
                cachedResponse.value,
              )
              ctx.commit(apiCall.statusName, ApiCallStatus.SUCCESS)
              console.log('Success [Cache]', apiCall.name, res.toObject())
              if (apiCall.resProcesser) apiCall.resProcesser(ctx, res, data)
              return Promise.resolve(res)
            }
          }
        }

        var headers = {}
        headers['domain'] = window.location.hostname
        console.log('Domain', headers['domain'])

        if (ut && ut !== '') {
          headers['Authorization'] = ut
        }

        if (apiCall.timeoutInSeconds) {
          var deadline = new Date()
          deadline.setSeconds(deadline.getSeconds() + apiCall.timeoutInSeconds)
          headers['deadline'] = deadline.getTime()
        }

        ctx.commit(apiCall.statusName, ApiCallStatus.CALLING)
        if (!apiCall.isStream) {
          return apiCall
            .serviceCall(req, headers)
            .then((res) => {
              ctx.commit(apiCall.statusName, ApiCallStatus.SUCCESS)
              console.log('Success', apiCall.name, res.toObject())
              if (apiCall.cache) {
                var value = apiCall.protobufToString(res)
                var cachedObject = JSON.stringify({
                  value: value,
                  savedAt: Date.now(),
                })
                localStorage.setItem(cacheKey, cachedObject)
                console.log('Caching', cacheKey, cachedObject)
              }
              if (apiCall.invalidateCache) {
                var invalidateCacheKey =
                  apiCall.invalidateCacheName +
                  ':' +
                  apiCall.invalidateKeyFn(data, ctx)
                localStorage.removeItem(invalidateCacheKey)
                console.log('Invalidating', invalidateCacheKey)
              }
              if (apiCall.resProcesser) apiCall.resProcesser(ctx, res, data)
              return res
            })
            .catch((err) => {
              ctx.commit(apiCall.statusName, ApiCallStatus.ERROR)
              console.log('Error', apiCall.name, err)
              if (apiCall.errProcesser) apiCall.errProcesser(err, ctx)
              throw err
            })
        } else {
          var streamCall = apiCall.serviceCall(req, headers)
          streamCall.on('data', function (res) {
            console.log('Update', apiCall.name, res.toObject())
            if (apiCall.updateProcesser) apiCall.updateProcesser(ctx, res, data)
          })
          streamCall.on('end', function () {
            ctx.commit(apiCall.statusName, ApiCallStatus.SUCCESS)
            console.log('Success', apiCall.name)
            if (apiCall.resProcesser) apiCall.resProcesser(ctx, null, data)
          })
          streamCall.on('error', function (err) {
            ctx.commit(apiCall.statusName, ApiCallStatus.ERROR)
            if (apiCall.errProcesser) apiCall.errProcesser(err, ctx, data)
            console.log('Error', apiCall.name, err)
          })
        }
      })
    }
  }
}
