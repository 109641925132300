var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.initialized ? _c('v-app', [_c('List'), _c('BaseLayout', [_c('router-view', {
    key: _vm.$route.fullPath
  })], 1), _c('footer', [_c('cookie-law', {
    attrs: {
      "theme": "blood-orange",
      "buttonText": "Agree"
    }
  }, [_c('div', {
    attrs: {
      "slot": "message"
    },
    slot: "message"
  }, [_vm._v(" We use cookies to ensure you get the best user experience on our website. By continuing to use this site, you agree to the use of these cookies. "), _c('router-link', {
    attrs: {
      "to": "privacy-policy"
    }
  }, [_vm._v("Find out more")])], 1)])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }