var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "px-0"
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex py-2"
  }, [_c('v-row', {
    staticClass: "pl-1 align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": 8,
      "md": 2
    }
  }, [_c('v-select', {
    staticClass: "ml-0 mb-2",
    attrs: {
      "items": _vm.curLanguages,
      "outlined": "",
      "hide-details": "",
      "label": "Language"
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.language,
      callback: function ($$v) {
        _vm.language = $$v;
      },
      expression: "language"
    }
  })], 1), _c('v-spacer', {
    staticClass: "hidden-sm-and-down"
  }), _vm.showSettings ? _c('v-col', {
    staticClass: "py-0 pr-1",
    attrs: {
      "cols": 12,
      "md": 7,
      "lg": 6
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": 6,
      "md": 3
    }
  }, [_c('v-select', {
    staticClass: "ml-0 font-weight-light",
    attrs: {
      "items": _vm.themes,
      "outlined": "",
      "hide-details": "",
      "label": "Theme"
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.theme,
      callback: function ($$v) {
        _vm.theme = $$v;
      },
      expression: "theme"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-1",
    attrs: {
      "cols": 6,
      "md": 3
    }
  }, [_c('v-select', {
    staticClass: "ml-0 font-weight-light",
    attrs: {
      "items": _vm.keyMaps,
      "outlined": "",
      "hide-details": "",
      "label": "Key map"
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.keyMap,
      callback: function ($$v) {
        _vm.keyMap = $$v;
      },
      expression: "keyMap"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-1 hidden-sm-and-down",
    attrs: {
      "cols": 3
    }
  }, [_c('v-select', {
    staticClass: "ml-0 font-weight-light",
    attrs: {
      "items": _vm.fontSizes,
      "outlined": "",
      "hide-details": "",
      "label": "Font size"
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.fontSize,
      callback: function ($$v) {
        _vm.fontSize = $$v;
      },
      expression: "fontSize"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-1 hidden-sm-and-down",
    attrs: {
      "cols": 3
    }
  }, [_c('v-select', {
    staticClass: "ml-0 font-weight-light",
    attrs: {
      "items": _vm.tabSizes,
      "outlined": "",
      "hide-details": "",
      "label": "Tab size"
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.tabSize,
      callback: function ($$v) {
        _vm.tabSize = $$v;
      },
      expression: "tabSize"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": 2,
      "align": "right"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": "accent",
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          on: {
            "click": _vm.removeCode
          }
        }, [_vm._v(_vm._s(_vm.mdiCached))])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Reset Code")])]), _c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": _vm.autoCompleteColor,
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          on: {
            "click": _vm.toggleAutocomplete
          }
        }, [_vm._v(_vm._s(_vm.mdiToggle))])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Auto complete")])]), _c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": _vm.closingBracketsColor,
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          on: {
            "click": _vm.toggleBracket
          }
        }, [_vm._v(_vm._s(_vm.mdiCodeParentheses))])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Auto closing bracket")])]), _c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": "gray",
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          on: {
            "click": _vm.toggleSettings
          }
        }, [_vm._v(_vm._s(_vm.mdiAccountCogOutline))])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Settings")])])], 1)], 1)], 1), _c('v-divider'), _c('v-row', [_c('v-col', {
    staticClass: "py-0 mt-1"
  }, [_c('CodeMirror', {
    ref: "myCm",
    class: _vm.fontSize,
    attrs: {
      "value": _vm.value,
      "options": _vm.cmOptions
    },
    on: {
      "ready": _vm.onCmReady,
      "input": _vm.onCodeChange
    }
  })], 1)], 1)], 1), _c('v-card', [_c('v-snackbar', {
    scopedSlots: _vm._u([{
      key: "action",
      fn: function (_ref5) {
        var attrs = _ref5.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "color": "red",
            "text": ""
          },
          on: {
            "click": function ($event) {
              _vm.snackbar = false;
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" Close ")])];
      }
    }]),
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" Please stay only on the coding tab during the test ! ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }