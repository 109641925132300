var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fill-height"
  }, [_c('test-page', {
    attrs: {
      "apiStatusList": [_vm.getHiringTestStatus],
      "errMsg": _vm.errMsg
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_vm.started & !_vm.testEndedLocally ? _c('v-row', {
          staticClass: "align-center text-center"
        }, [_c('v-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/"
          }
        }, [_c('img', {
          attrs: {
            "alt": "[C]",
            "height": "35",
            "width": "50",
            "src": require('../../assets/images/CodeDrillsLight.svg')
          }
        })])], 1), _c('v-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiTimer) + " ")]), _vm._v(" " + _vm._s(_vm.timeTillQuestionEnd) + "/ " + _vm._s(_vm.questionDuration) + " ")], 1), _c('v-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('b', [_vm._v("Total Time:")]), _vm._v(" " + _vm._s(_vm.timeTillTestEnd) + " / " + _vm._s(_vm.testDuration) + " ")]), _c('v-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('v-btn', {
          staticClass: "white--text text-none font-weight-medium text-body-1",
          attrs: {
            "color": "danger",
            "elevation": "0"
          },
          on: {
            "click": _vm.endTestNavigation
          }
        }, [_vm._v(" End Test ")])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('v-row', {
          staticStyle: {
            "height": "100%"
          }
        }, [!_vm.loading ? _c('v-col', {
          staticClass: "fill-height"
        }, [!_vm.started ? _c('test-start-page', {
          attrs: {
            "testView": _vm.currentTestView
          }
        }) : _vm._e(), _vm.testEndedLocally ? _c('test-end-page', {
          attrs: {
            "testView": _vm.currentTestView
          }
        }) : _vm._e(), _vm.started && !_vm.testEndedLocally ? _c('test', {
          attrs: {
            "testView": _vm.currentTestView
          }
        }) : _vm._e()], 1) : _vm._e()], 1)];
      },
      proxy: true
    }])
  }), _vm.yetToStart && !_vm.isTestEnded ? _c('v-row', [_c('v-col', [_c('v-alert', {
    staticClass: "text-h5",
    attrs: {
      "type": "warning"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.waitMessage)
    }
  })])], 1)], 1) : _vm._e(), _vm.isTestEnded ? _c('v-row', [_c('v-col', [_c('v-alert', {
    staticClass: "text-h5",
    attrs: {
      "type": "warning"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.endMessage)
    }
  })])], 1)], 1) : _vm._e(), _c('BigConfirmation', {
    ref: "end",
    attrs: {
      "imageUrl": "images/ConfirmTestEnd.png"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }