<template>
  <v-card class="shift-up" elevation="0" max-height="800px">
    <div class="d-flex flex-column">
      <v-row no-gutters class="pa-0 ma-0">
        <v-col :cols="6" :md="3" class="py-0">
          <v-select
            :items="curLanguages"
            outlined
            class="ml-0 mb-2"
            hide-details
            dense
            v-model="language"
            @change="saveEditorConfig"
            label="Language"
          >
          </v-select>
        </v-col>
        <v-spacer class="hidden-sm-and-down"></v-spacer>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :color="fullscreenColor" large v-bind="attrs" v-on="on">
              <v-icon class="text-right" @click="toggleEditorFS">{{
                mdiFullscreen
              }}</v-icon>
            </v-btn>
          </template>
          <span>Fullscreen</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :color="closingBracketsColor"
              large
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="text-right" @click="toggleBracket">{{
                mdiCodeParentheses
              }}</v-icon>
            </v-btn>
          </template>
          <span>Auto closing bracket</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" large v-bind="attrs" v-on="on">
              <v-icon class="text-right" @click="removeCode">{{
                mdiCached
              }}</v-icon>
            </v-btn>
          </template>
          <span>Reset Code</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :color="autoCompleteColor"
              large
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="text-right" @click="toggleAutocomplete">{{
                mdiToggle
              }}</v-icon>
            </v-btn>
          </template>
          <span>Auto complete</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" large v-bind="attrs" v-on="on">
              <v-icon class="text-right" @click="toggleSettings">{{
                mdiAccountCogOutline
              }}</v-icon>
            </v-btn>
          </template>
          <span>Editor Settings</span>
        </v-tooltip>

        <v-dialog hide-overlay v-model="showSettings" :max-width="350">
          <v-col cols="auto" class="code-settings">
            <span class="text-h6"> Editor Settings </span>
            <v-select
              dense
              :items="themes"
              outlined
              class="py-2 ml-0 font-weight-light"
              hide-details
              v-model="theme"
              @change="saveEditorConfig"
              label="Theme"
            >
            </v-select>
            <v-select
              dense
              :items="fontSizes"
              outlined
              class="py-2 ml-0 font-weight-light"
              hide-details
              v-model="fontSize"
              @change="saveEditorConfig"
              label="Font size"
            >
            </v-select>
            <v-select
              dense
              :items="tabSizes"
              outlined
              class="py-2 ml-0 font-weight-light"
              hide-details
              v-model="tabSize"
              @change="saveEditorConfig"
              label="Tab size"
            >
            </v-select>
            <v-select
              dense
              :items="keyMaps"
              outlined
              class="py-2 ml-0 font-weight-light"
              hide-details
              v-model="keyMap"
              @change="saveEditorConfig"
              label="Key map"
            >
            </v-select>
          </v-col>
        </v-dialog>
      </v-row>
    </div>
    <v-divider></v-divider>
    <CodeMirror
      ref="hiringCm"
      :value="value"
      @ready="onCmReady"
      @input="onCodeChange"
      :options="cmOptions"
      :class="[fontSize, 'pb-8']"
    ></CodeMirror>
    <div class="px-lg-8 px-4 mb-5" ref="codeaction">
      <slot name="actions" />
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { CodeMirror as CodeMirrorObject } from 'vue-codemirror'
import { codemirror as CodeMirror } from 'vue-codemirror'
import 'codemirror/mode/python/python.js'
import 'codemirror/mode/clike/clike.js'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/ruby/ruby.js'
import 'codemirror/theme/dracula.css'
import 'codemirror/theme/solarized.css'
import 'codemirror/theme/idea.css'
import 'codemirror/keymap/emacs.js'
import 'codemirror/keymap/vim.js'
import 'codemirror/keymap/sublime.js'
import 'codemirror/lib/codemirror'
import 'codemirror/addon/comment/comment.js'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/search/searchcursor'
import 'codemirror/addon/search/search'
import 'codemirror/addon/display/placeholder'
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/mode/sql/sql.js'
import 'codemirror/mode/go/go.js'
import 'codemirror/mode/dart/dart.js'
import 'codemirror/mode/r/r.js'
import 'codemirror/mode/php/php.js'

import '@/addons/cpp-hint'
import '@/addons/java-hint'
import '@/addons/kotlin-hint'
import '@/addons/python-hint'
import '@/addons/csharp-hint'
import '@/addons/go-hint'
import '@/addons/dart-hint'
import '@/addons/r-hint'
import '@/addons/php-hint'

import {
  mdiCached,
  mdiCheckUnderlineCircle,
  mdiAccountCogOutline,
  mdiCodeParentheses,
  mdiFullscreen,
} from '@mdi/js'

export default {
  data() {
    return {
      mdiFullscreen,
      mdiCached: mdiCached,
      mdiToggle: mdiCheckUnderlineCircle,
      mdiAccountCogOutline: mdiAccountCogOutline,
      mdiCodeParentheses: mdiCodeParentheses,
      tempClipboardData: undefined,
      autoCompleteColor: 'primary',
      closingBracketsColor: 'primary',
      fullscreenColor: 'gray',
      enableAutocomplete: true,
      autoCloseBrackets: true,
      showSettings: false,
      tabSize: 4,
      theme: 'dracula',
      language: null,
      keyMap: 'default',
      fontSize: 'xs',
      snackbar: false,
      languages: [
        { text: 'C++', value: 'CPP_17' },
        { text: 'Java', value: 'JAVA_11' },
        { text: 'Kotlin', value: 'KOTLIN_1_3' },
        { text: 'Python', value: 'PYTHON_3' },
        { text: 'MySQL 8', value: 'MY_SQL_8' },
        { text: 'JavaScript', value: 'JAVASCRIPT' },
        { text: 'Ruby', value: 'RUBY' },
        { text: 'C#', value: 'CSHARP' },
        { text: 'TypeScript', value: 'TYPESCRIPT' },
        { text: 'Go', value: 'GO' },
        { text: 'Dart', value: 'DART' },
        { text: 'R', value: 'R' },
        { text: 'PHP', value: 'PHP' },
      ],
      themes: [
        { text: 'Idea', value: 'idea' },
        { text: 'Dracula', value: 'dracula' },
        { text: 'Solarized Dark', value: 'solarized dark' },
        { text: 'Solarized Light', value: 'solarized light' },
        { text: 'Default', value: 'default' },
      ],
      keyMaps: [
        { text: 'Vim', value: 'vim' },
        { text: 'Emacs', value: 'emacs' },
        { text: 'Sublime', value: 'sublime' },
        { text: 'Basic', value: 'default' },
      ],
      fontSizes: [
        { text: 'Very Small', value: 'xs' },
        { text: 'Small', value: 'small' },
        { text: 'Normal', value: 'normal' },
        { text: 'Large', value: 'large' },
      ],
      tabSizes: [
        { text: '2 Spaces', value: 2 },
        { text: '4 Spaces', value: 4 },
      ],
      cmModeMap: {
        CPP_17: 'text/x-c++src',
        JAVA_11: 'text/x-java',
        KOTLIN_1_3: 'text/x-kotlin',
        PYTHON_3: 'text/x-python',
        MY_SQL_8: 'text/x-mysql',
        JAVASCRIPT: 'text/javascript',
        RUBY: 'text/x-ruby',
        CSHARP: 'text/x-csharp',
        TYPESCRIPT: 'text/typescript',
        GO: 'text/x-go',
        DART: 'dart',
        R: 'text/x-rsrc',
        PHP: 'text/x-php',
      },
      cmHintModeMap: {
        CPP_17: CodeMirrorObject.hint.cpp,
        JAVA_11: CodeMirrorObject.hint.java,
        KOTLIN_1_3: CodeMirrorObject.hint.kotlin,
        PYTHON_3: CodeMirrorObject.hint.python,
        CSHARP: CodeMirrorObject.hint.csharp,
        GO: CodeMirrorObject.hint.go,
        DART: CodeMirrorObject.hint.dart,
        R: CodeMirrorObject.hint.r,
        PHP: CodeMirrorObject.hint.php,
      },
    }
  },
  props: {
    value: {
      type: String,
      required: false,
    },
    // Enable this will trigger the procter
    hiringTestMode: {
      type: Boolean,
      required: false,
      default: true,
    },
    codeAction: {
      type: String,
      required: true,
      default: 'HIDDEN',
    },
    allowedLanguages: {
      type: Array,
      required: true,
    },
    evaluationType: {
      type: Number,
      required: false,
    },
  },
  components: {
    CodeMirror,
  },
  computed: {
    ...mapState('user', ['userPreference', 'user']),
    ...mapState('candidate', ['hiringCodeEditorFullscreen']),
    ...mapGetters('user', ['languageKeys']),
    codemirror() {
      return this.$refs.hiringCm.codemirror
    },
    editorConfig() {
      return this.userPreference && this.userPreference.getEditorConfig()
    },
    cmOptions() {
      return {
        tabSize: this.tabSize,
        indentUnit: this.tabSize,
        indentWithTabs: true,
        smartIndent: true,
        mode: this.cmModeMap[this.language],
        autoCloseBrackets: this.autoCloseBrackets,
        lineNumbers: true,
        line: true,
        styleActiveLine: true,
        matchBrackets: true,
        lineWrapping: true,
        theme: this.theme,
        foldGutter: true,
        keyMap: this.keyMap,
        extraKeys: {
          'Ctrl-/': 'toggleComment',
          'Cmd-/': 'toggleComment',
        },
      }
    },
    curLanguages() {
      if (this.evaluationType) return [this.languages[4]]
      console.log('SET LANGUAGE')
      let safePref = false
      let cur = this.languages.filter((lg) => {
        return (
          this.allowedLanguages.length == 0 ||
          this.allowedLanguages.find((l) => l == lg.value)
        )
      })
      for (let okLang of cur) {
        if (okLang.value === this.language) {
          safePref = true
        }
      }
      if (!safePref) {
        this.language = cur[0].value
      }
      return cur
    },
  },
  watch: {
    editorConfig: function (config) {
      this.syncConfig(config)
    },
    defaultCode: function (newDefaultCode) {
      this.value = newDefaultCode
    },
    language: function (newLanguage) {
      this.$emit('setLanguage', newLanguage)
    },
    codeAction: function (n, o) {
      if (n !== 'HIDDEN') {
        this.codemirror.setSize('100%', '330px')
      } else {
        this.codemirror.setSize('100%', '550px')
      }
    },
    allowedLanguages: function (newList) {
      if (newList && newList.length > 0) {
        this.language = newList[0]
      }
    },
  },
  methods: {
    ...mapMutations('user', ['setEditorConfig']),
    ...mapMutations('candidate', ['setHiringCodeEditorFullscreen']),
    ...mapActions('user', ['saveUserPreference']),
    syncConfig(config) {
      if (!config) return
      console.log('SYNC CONFIG')
      this.language =
        config.getLanguage() != 0
          ? this.languageKeys[config.getLanguage()]
          : this.curLanguages.length > 0
          ? this.curLanguages[0]
          : 'CPP_17'
      this.tabSize = config.getTabSize() || 4
      this.theme = config.getTheme() || 'dracula'
      this.keyMap = config.getKeyMap() || 'default'
      this.fontSize = config.getFontSize() || 'xs'
    },
    onCmReady(cm) {
      console.log('Codemirror is ready !')
      // width, height
      this.codemirror.setSize('100%', '550px')
      cm.on('keypress', (cm, e) => {
        if (this.enableAutocomplete) {
          cm.showHint({
            completeSingle: false,
            hint: this.cmHintModeMap[this.language],
          })
        }
      })
      console.log('HINT MODE....', this.language),
        cm.on('copy', (cm, event) => {
          this.tempClipboardData = window.getSelection().toString()
        })
      cm.on('paste', (cm, e) => {
        let pasteContent = e.clipboardData.getData('text')
        pasteContent = pasteContent.replaceAll('\r', '')
        if (this.hiringTestMode && pasteContent !== this.tempClipboardData) {
          console.log('Pasting from external source is prohibited')
          e.preventDefault()
        }
      })
    },
    onCodeChange(newValue) {
      this.$emit('input', newValue)
    },
    saveEditorConfig() {
      this.setEditorConfig({
        language: this.language,
        tabSize: this.tabSize,
        theme: this.theme,
        keyMap: this.keyMap,
        fontSize: this.fontSize,
      })
      if (this.user) {
        this.saveUserPreference()
      }
    },
    removeCode() {
      this.$emit('resetCode')
    },
    toggleAutocomplete() {
      this.enableAutocomplete = !this.enableAutocomplete
      this.autoCompleteColor = this.enableAutocomplete ? 'primary' : 'gray'
    },
    toggleSettings() {
      this.showSettings = !this.showSettings
    },
    toggleBracket() {
      this.autoCloseBrackets = !this.autoCloseBrackets
      this.closingBracketsColor = this.autoCloseBrackets ? 'primary' : 'gray'
    },
    toggleEditorFS() {
      this.setHiringCodeEditorFullscreen(!this.hiringCodeEditorFullscreen)
      this.fullscreenColor = this.hiringCodeEditorFullscreen
        ? 'primary'
        : 'gray'
    },
    languageGuard() {
      let safePref = false
      let cur = this.languages.filter((lg) => {
        return (
          this.allowedLanguages.length == 0 ||
          this.allowedLanguages.find((l) => l == lg.value)
        )
      })
      for (let okLang of cur) {
        if (okLang.value === this.language) {
          safePref = true
        }
      }
      if (!safePref) {
        this.language = cur[0].value
      }
    },
  },
  mounted() {
    this.$emit('setLanguage', this.language)
    this.syncConfig(this.editorConfig)
    this.languageGuard()
  },
}
</script>
<style scoped>
@import '~@/assets/css/hiringeditor.css';
.code-settings {
  background: white;
  border: 2px solid grey;
  border-radius: 0.5rem;
}

.vue-codemirror.small .CodeMirror {
  font-size: 13px !important;
}

.vue-codemirror.normal .CodeMirror {
  font-size: 16px !important;
}

.vue-codemirror.large .CodeMirror {
  font-size: 20px !important;
}

.code-actions-content {
  position: absolute;
  bottom: 0;
  background-color: white;
  z-index: 100;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* extreme hack, separating settings would be a massive pain */
/* so just shifted the settings bar up so that it appears like */
/* its in the bar lol */
.shift-up {
  margin-top: -3.05rem;
}
</style>
