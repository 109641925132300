<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="!rejection && cancel"
    @click:outside="!rejection && cancel"
    persistent
    :retain-focus="false"
  >
    <v-card class="pa-5">
      <v-card-text v-show="!!message" class="pa-4 text-h6 text-center">
        {{ message }}
      </v-card-text>
      <v-checkbox
        v-if="showHideMessage"
        v-model="hideDialog"
        label="Don't show this message again"
        class="pa-4"
      ></v-checkbox>
      <v-card-actions class="py-4 px-8 justify-center">
        <v-btn
          v-if="rejection"
          large
          @click.native="cancel"
          elevation="0"
          class="text-none text-subtitle-1 font-weight-medium px-2"
        >
          Cancel
        </v-btn>
        <v-spacer v-if="rejection"></v-spacer>
        <v-btn
          large
          :color="options.color"
          @click.native="agree"
          elevation="0"
          class="text-none text-subtitle-1 font-weight-medium px-2"
        >
          <span v-if="rejection"> OK </span>
          <span v-else> Yes </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    rejection: {
      default: true,
      required: false,
      type: Boolean,
    },
    showHideMessage: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    // title: null,
    hideDialog: false,
    options: {
      color: 'primary',
      width: 600,
      zIndex: 200,
    },
  }),
  methods: {
    open(message, options) {
      console.log('Dailong Selection is ..', this.rejection)
      this.dialog = !this.hideDialog
      //   this.title = title;
      this.message = message
      this.options = Object.assign(this.options, options)
      if (this.hideDialog) {
        this.$nextTick(() => {
          this.agree()
        })
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      console.log('cancel...')
      this.resolve(false)
      this.dialog = false
      this.hideDialog = false
    },
    clear() {
      if (this.dialog) this.dialog = false
      if (this.resolve) {
        this.reject(new Error('Force closed'))
        ;(this.resolve = null), (this.reject = null)
      }
    },
  },
}
</script>
