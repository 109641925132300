<template>
  <v-app v-if="initialized">
    <List />
    <BaseLayout>
      <router-view :key="$route.fullPath" />
    </BaseLayout>
    <footer>
      <cookie-law theme="blood-orange" buttonText="Agree">
        <div slot="message">
          We use cookies to ensure you get the best user experience on our
          website. By continuing to use this site, you agree to the use of these
          cookies.
          <router-link to="privacy-policy">Find out more</router-link>
        </div>
      </cookie-law>
    </footer>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import List from './components/notifs/List.vue'
import BaseLayout from './layouts/BaseLayout.vue'
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'app',
  data() {
    return {}
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from, next) {
        document.title = to.meta.title || 'CodeDrills'
      },
    },
  },
  computed: {
    ...mapState('user', ['initialized']),
  },
  methods: {
    ...mapMutations('user', ['setSubscriptionToken']),
    redirect() {
      //console.log("Redirecting?", redirect);
      return redirect
    },
  },
  components: {
    BaseLayout,
    List,
    CookieLaw,
  },
  created() {
    if (this.$messaging) {
      this.$messaging
        .requestPermission()
        .then(() => this.$messaging.getToken())
        .then((token) => {
          console.log('Token: ', token)
          this.setSubscriptionToken(token)

          this.$messaging.onMessage((payload) => {
            console.log('Message received. ...', payload)
            alert(payload.notification.body)
          })
          console.log('Registered onMessage listener')
        })
        .catch((err) => {
          console.log('Unable to notify', err)
        })
    }
  },
}
</script>

<style>
html,
body {
  background-color: #fbfbfb;
}
</style>
